import parse from "html-react-parser";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteServicesMutation } from "../../features/services/servicesApi";
import swalWithBootstrapButtons from "../../hooks/swalWithBootstrapButtons";

export default function ServiceTableRow({ service = {} }) {
  const { id, title, description, icon } = service || {};
  const [
    deleteService,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeleteServicesMutation();

  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      swalWithBootstrapButtons.fire(
        "Deleted!",
        "Service Delete Successfully.",
        "success"
      );
    } else if (deletedIsError) {
      swalWithBootstrapButtons.fire(
        "Error",
        "Service Delete Error! :)",
        "error"
      );
    }
  }, [deletedIsError, deletedIsSuccess]);

  const handleDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          deleteService(id);
        }
      });
  };

  return (
    <tr key={id} className="text-center">
      <td>{id}</td>
      <td>{title}</td>
      <td>{description}</td>
      <td>
        <div className="feature-box-01">{parse(icon)}</div>
      </td>
      <td>
        <Link
          className="mx-2 btn btn-outline-success"
          to={`/user/service/edit/${id}`}
        >
          Edit
        </Link>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
