import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useAddServicesIconMutation,
  useGetServicesIconSingleMutation,
  useUpdateServicesIconMutation,
} from "../../features/services/servicesApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ServiceIconAdd() {
  const [iconTag, setIconTag] = useState("");
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [error, setError] = useState(null);

  const [
    getServiceIconSingle,
    { data: serviceData, isSuccess: isSuccessSingleData },
  ] = useGetServicesIconSingleMutation();
  const [
    addServicesIcon,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddServicesIconMutation();
  const [
    updateservicesIcon,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateServicesIconMutation();
  const { id } = useParams();

  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Services Tag Insert Successfully");
      navigate("/user/service/icon/list");
    } else if (addIsError) {
      toast.error("Services Tag Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Services Tag Update Successfully");
      navigate("/user/service/icon/list");
    } else if (updatedIsError) {
      toast.error("Services Tag Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && serviceData?.id) {
      setIconTag(serviceData?.iconTag);
    }
  }, [isSuccessSingleData, serviceData]);

  useEffect(() => {
    if (id) {
      getServiceIconSingle(id);
    }
  }, [id, getServiceIconSingle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (iconTag) {
      if (id) {
        updateservicesIcon({ id, data: { iconTag } });
      } else {
        addServicesIcon({ iconTag });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="aboutMe"
      data-nav-tooltip="About Me Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Icon Tag">
            <input
              name="IconTag"
              onChange={(e) => setIconTag(e.target.value)}
              value={iconTag}
              id="title"
              placeholder="IconTag*"
              className={`form-control ${
                error ? (!iconTag ? "invalid" : "") : ""
              }`}
              type="text"
            />
            <br></br>
            <p>
              * Please add <mark>"theme-bg"</mark> class
            </p>
            <br></br>
            <p>
              * Get Icon Tag from{" "}
              <a
                href="https://fontawesome.com/v5/search"
                target="_blank"
                rel="noreferrer"
              >
                FontAwesome
              </a>
            </p>
          </FormGroup>
        </div>
        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
