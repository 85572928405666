import { useGetContactAddressQuery } from "../../features/contact/contactApi";

export default function ContactInfo() {
  const { data: contactAddressData, isSuccess: isSuccessSingleData } =
    useGetContactAddressQuery();
  let content = "";
  if (isSuccessSingleData && contactAddressData) {
    content = (
      <>
        <h4>{contactAddressData?.title}</h4>
        <p>{contactAddressData?.description}</p>
        <ul>
          <li className="media">
            <i className="ti-map" />
            <span className="media-body">{contactAddressData?.address}</span>
          </li>
          <li className="media">
            <i className="ti-email" />
            <span className="media-body">{contactAddressData?.email}</span>
          </li>
          <li className="media">
            <i className="ti-mobile" />
            <span className="media-body">{contactAddressData?.phone}</span>
          </li>
        </ul>
      </>
    );
  }
  return <div className="contact-info">{content}</div>;
}
