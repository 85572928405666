import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import app from "../../firebase";

export default function imageUpload(file, address) {
  const storage = getStorage(app);

  // Create the file metadata
  // /** @type {any} */
  // const metadata = {
  //   contentType: "image/jpeg",
  // };

  // Upload file and metadata to the object 'images/mountains.jpg'
  const storageRef = ref(storage, `${address}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return uploadTask;
}
