import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Layout from "./Layout";
import PreLoader from "./PreLoader";

export default function Content({ children }) {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  return (
    <>
      {load && <PreLoader />}
      <div>
        <Layout>{children}</Layout>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
}
