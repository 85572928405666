import { Link, useNavigate } from "react-router-dom";

const BackBtn = () => {
  let navigate = useNavigate();
  return (
    <Link className="demo-back-link" onClick={() => navigate(-1)} to="#">
      <i className="fas fa-arrow-left" />
    </Link>
  );
};
export default BackBtn;
