import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import SocialMediaTableRow from "../../components/User/SocialMediaTableRow";
import { useGetSocialMediaQuery } from "../../features/user/userApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
export default function UserSocialMediaList() {
  const { height } = useWindowDimensions();

  const { data: SocialMedia, isLoading, isError, isSuccess } = useGetSocialMediaQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && SocialMedia.length === 0) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && SocialMedia.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Sl</th>
            <th>Icon</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {SocialMedia.map((social) => (
            <SocialMediaTableRow key={social?.id} social={social} />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
    <section id="testimonial" data-nav-tooltip="Skill" className="pp-section pp-scrollable section dark-bg" style={{ height: `${height}px` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">{content}</div>
        </div>
      </div>
    </section>
  );
}
