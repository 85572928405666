import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";
export const experienceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExperience: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("experience");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Experience"],
    }),
    getExperienceSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`experience/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addExperience: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("experience", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Experience"],
    }),
    updateExperience: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`experience/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Experience"],
    }),
    deleteExperience: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`experience`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Experience"],
    }),
  }),
});

export const { useGetExperienceQuery, useAddExperienceMutation, useGetExperienceSingleMutation, useUpdateExperienceMutation, useDeleteExperienceMutation } = experienceApi;
