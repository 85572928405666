import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const skillApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkills: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("skills");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Skills"],
    }),
    getSkillSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`/skills/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addSkill: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("skills", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Skills"],
    }),
    updateSkill: builder.mutation({
      async queryFn({ id, data }) {
        try {
          let update = await updateCollection(`skills/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Skills"],
    }),
    deleteSkill: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`skills`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Skills"],
    }),
    getSkillText: builder.query({
      async queryFn() {
        try {
          const data = await getSingleCollection(`/skillText`);
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["SkillText"],
    }),
    updateSkillText: builder.mutation({
      async queryFn(data) {
        try {
          data.id = 1;
          let update = await updateCollection(`skillText`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["SkillText"],
    }),
  }),
});

export const {
  useGetSkillTextQuery,
  useGetSkillsQuery,
  useUpdateSkillTextMutation,
  useGetSkillSingleMutation,
  useAddSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
} = skillApi;
