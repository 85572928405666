import EducationTableRow from "../../components/Education/EducationTableRow";
import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import { useGetEducationQuery } from "../../features/education/educationApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
export default function EducationList() {
  const { height } = useWindowDimensions();
  const {
    data: educationData,
    isLoading,
    isError,
    isSuccess,
  } = useGetEducationQuery();

  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && educationData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && educationData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Sl</th>
            <th>Title</th>
            <th>Institute</th>
            <th>Year</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {educationData.map((education) => (
            <EducationTableRow key={education?.id} education={education} />
          ))}
        </tbody>
      </Table>
    );
  }

  return (
      <section
        id="educations"
        data-nav-tooltip="Educations"
        className="pp-section pp-scrollable section dark-bg"
        style={{ height: `${height}px` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">{content}</div>
          </div>
        </div>
      </section>
  );
}
