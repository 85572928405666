export default function MediaCount({ number = "", textStart = "", textEnd = "" }) {
  return (
    <div className="col-auto">
      <div className="media align-items-center">
        <span className="count">{number}</span>
        <div className="media-body">
          {textStart}
          <br />
          {textEnd}
        </div>
      </div>
    </div>
  );
}
