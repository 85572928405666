import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const testimonialsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTestimonials: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("testimonials");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Testimonials"],
    }),
    getTestimonialSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`testimonials/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addTestimonial: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("testimonials", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Testimonials"],
    }),
    updateTestimonial: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`testimonials/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Testimonials"],
    }),
    deleteTestimonial: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`testimonials`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Testimonials"],
    }),
  }),
});

export const { useGetTestimonialsQuery, useGetTestimonialSingleMutation, useAddTestimonialMutation, useUpdateTestimonialMutation, useDeleteTestimonialMutation } = testimonialsApi;
