import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import imageUpload from "../../features/firebase/imageUpload";
import makeImageUrl from "../../features/firebase/makeImageUrl";
import {
  useAddPortfolioMutation,
  useGetPortfolioSingleMutation,
  useGetPortfolioTagQuery,
  useUpdatePortfolioMutation,
} from "../../features/portfolio/portfolioApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
export default function PortfolioAdd() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [tag, setTag] = useState(null);
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const [prevImage, setPrevImage] = useState("");
  const navigate = useNavigate();

  const [
    addPortfolio,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddPortfolioMutation();
  const [
    getPortfolioSingle,
    { data: portfolioData, isSuccess: isSuccessSingleData },
  ] = useGetPortfolioSingleMutation();
  const [
    updatePortfolio,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdatePortfolioMutation();
  const { id } = useParams();

  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Portfolio Insert Successfully");
      navigate("/user/portfolio/list");
    } else if (addIsError) {
      toast.error("Portfolio Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Portfolio Update Successfully");
      navigate("/user/portfolio/list");
    } else if (updatedIsError) {
      toast.error("Portfolio Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && portfolioData?.id) {
      setTitle(portfolioData?.title);
      setPrevImage(portfolioData?.image);
      setPortfolioLink(portfolioData?.link);
      setTag(portfolioData?.tag);
      setDescription(portfolioData?.description);
    }
  }, [isSuccessSingleData, portfolioData]);
  console.log(tag);
  console.log(portfolioData);
  useEffect(() => {
    if (id) {
      getPortfolioSingle(id);
    }
  }, [id, getPortfolioSingle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && image) {
      let image_url = prevImage;
      if (image) {
        const image_upload = await imageUpload(image, "Portfolio");
        if (image_upload.state === "success")
          image_url = await makeImageUrl(image, "Portfolio");
      }
      if (id) {
        updatePortfolio({
          id,
          data: {
            title,
            description,
            image: image_url,
            link: portfolioLink,
            tag,
          },
        });
      } else {
        addPortfolio({
          title,
          description,
          image: image_url,
          link: portfolioLink,
          tag,
        });
      }
    } else {
      setError(true);
    }
  };
  const { data: portfolioTagData, isSuccess: tagIsSuccess } =
    useGetPortfolioTagQuery();
  let tagOption = [];
  if (tagIsSuccess && portfolioTagData?.length > 0) {
    tagOption = portfolioTagData.map((tag) => {
      return {
        value: tag?.tag,
        label: tag?.tag,
      };
    });
  }
  return (
    <section
      id="aboutMe"
      data-nav-tooltip="About Me Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Title">
            <input
              name="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id="title"
              placeholder="Title*"
              className={`form-control ${
                error ? (!title ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Description">
            <input
              name="Description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              id="description"
              placeholder="Description*"
              className={`form-control ${
                error ? (!description ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="PortfolioLink">
            <input
              type="text"
              name="PortfolioLink"
              onChange={(e) => setPortfolioLink(e.target.value)}
              id="portfolioLink"
              placeholder="PortfolioLink"
              className={`form-control ${
                error ? (!portfolioLink ? "invalid" : "") : ""
              }`}
              value={portfolioLink}
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Tag">
            <Select
              options={tagOption}
              isMulti
              value={tag}
              onChange={setTag}
              className={`form-select text-dark bg-dark`}
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Image">
            <input
              type="file"
              name="Image"
              onChange={(e) => setImage(e.target.files[0])}
              id="image"
              placeholder="Image"
              className={`form-control ${
                error ? (!image ? "invalid" : "") : ""
              }`}
            />
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
