import { useState } from "react";
import { useGetUserDataQuery } from "../../features/user/userApi";
import TypingAnimation from "../TypingAnimation";

export default function HomeBanner() {
  const [imageWidth, setImageWidth] = useState("");
  const { data: userData, isLoading, isError, isSuccess } = useGetUserDataQuery();
  let content = "";
  let contentImage = "";
  if (isLoading) {
    contentImage = content = <p>Loading..</p>;
    // } else if (!isLoading && isError) {
    //   contentImage = content = <Error message="There is some problem" />;
    // } else if (isSuccess && !userData?.id) {
    //   contentImage = content = <Error message="There is some problem" />;
  } else if (isSuccess && userData?.id) {
    const { firstTitle, name, title, secondTitle, description, cv, image } = userData || {};
    contentImage = <img src={image} title="" alt="" />;
    let titleArr = secondTitle.split(",");
    content = (
      <div className="type-box">
        <h6>{firstTitle}</h6>
        <h1 className="font-alt">{name}</h1>
        <p className="lead">
          {title} <TypingAnimation title={titleArr} />
        </p>
        <p className="desc">{description}</p>
        <div className="btn-bar">
          <a className="px-btn px-btn-theme" href={cv} target="_blank" rel="noreferrer">
            Donwload CV
          </a>
        </div>
      </div>
    );
  }
  return (
    <section id="home" data-nav-tooltip="Home" className="pp-section pp-scrollable">
      <div className="home-banner">
        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-6">{content}</div>
            <div className="col-lg-6">
              <div className="hb-img">{contentImage}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
