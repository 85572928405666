import parse from "html-react-parser";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteSocialMediaMutation } from "../../features/user/userApi";
import swalWithBootstrapButtons from "../../hooks/swalWithBootstrapButtons";

export default function SocialMediaTableRow({ social = {} }) {
  const { id, link, icon } = social || {};
  const [
    deleteSocialMedia,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeleteSocialMediaMutation();
  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      swalWithBootstrapButtons.fire(
        "Deleted!",
        "Social Media Delete Successfully.",
        "success"
      );
    } else if (deletedIsError) {
      swalWithBootstrapButtons.fire(
        "Error",
        "Social Media Delete Error! :)",
        "error"
      );
    }
  }, [deletedIsError, deletedIsSuccess]);
  const handleDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          deleteSocialMedia(id);
        }
      });
  };

  return (
    <tr key={id} className="text-center">
      <td>{id}</td>
      <td>{parse(icon)}</td>
      <td>{link}</td>
      <td>
        <Link
          className="mx-2 btn btn-outline-success"
          to={`/user/social/media/edit/${id}`}
        >
          Edit
        </Link>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
