import makeUrl from "../../hooks/makeUrl";

export default function PortfolioItem({ portfolio = {} }) {
  const { id, image, link, description, tag, title } = portfolio || {};
  let classes = tag
    ?.map((t) => t?.value)
    .join(" ")
    .toString();
  let url = makeUrl(link);

  return (
    <div className={`grid-item ${classes}`}>
      <div className="portfolio-box-01">
        <div className="portfolio-info">
          <h5 className="white-color font-weight-bold">{title}</h5>
          <span>{description}</span>
        </div>
        <div className="portfolio-img">
          <img src={image} title="" alt="" />
          <div className="portfolio-icon">
            <a
              href={url}
              className="gallery-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ti-plus" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
