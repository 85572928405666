export default function GoogleMap() {
  return (
    <div className="google-map">
      <div className="embed-responsive embed-responsive-21by9">
        <iframe
          className="embed-responsive-item"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.0741050383667!2d90.41056071218732!3d23.744736678585248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b9e0c6d3f7e7%3A0x92182272a2f274cb!2sAyesha%20Shopping%20Complex!5e0!3m2!1sen!2sbd!4v1690880179489!5m2!1sen!2sbd"
          allowFullScreen=""
        />
      </div>
    </div>
  );
}
