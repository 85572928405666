export default function Skill({ skill = {} }) {
  const { title, percentage } = skill || {};
  return (
    <div className="skill-lt">
      <h6>{title}</h6>
      <div className="skill-bar">
        <div className="skill-bar-in" style={{ width: `${percentage}%` }}>
          <span data-toggle="tooltip" title={`${percentage}%`} />
        </div>
      </div>
    </div>
  );
}
