import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Content from "./layout/Content";
import AboutMeUpdate from "./pages/About/AboutMeUpdate";
import Abouts from "./pages/About/Abouts";
import BlogAdd from "./pages/Blog/BlogAdd";
import BlogList from "./pages/Blog/BlogList";
import Blogs from "./pages/Blog/Blogs";
import ContactUs from "./pages/Contact/ContactUs";
import EducationAdd from "./pages/Education/EducationAdd";
import EducationList from "./pages/Education/EducationList";
import ExperienceAdd from "./pages/Experience/ExprienceAdd";
import ExperienceList from "./pages/Experience/ExprienceList";

import PrivateOutlet from "./components/PrivateOutlet";
import PublicOutlet from "./components/PublicOutlet";
import { AuthProvider } from "./contexts/AuthContext";
import ContactAddressUpdate from "./pages/Contact/ContactAddressUpdate";
import ContactList from "./pages/Contact/ContactList";
import Home from "./pages/Home/Home";
import PortfolioAdd from "./pages/Portfolio/PortfolioAdd";
import PortfolioList from "./pages/Portfolio/PortfolioList";
import PortfolioTagAdd from "./pages/Portfolio/PortfolioTagAdd";
import PortfolioTagList from "./pages/Portfolio/PortfolioTagList";
import Portfolios from "./pages/Portfolio/Portfolios";
import ServicesAdd from "./pages/Services/ServiceAdd";
import ServiceIconAdd from "./pages/Services/ServiceIconAdd";
import ServiceIconList from "./pages/Services/ServiceIconList";
import ServiceList from "./pages/Services/ServiceList";
import Servicess from "./pages/Services/Servicess";
import SkillsAdd from "./pages/Skill/SkillAdd";
import SkillList from "./pages/Skill/SkillList";
import SkillsTextUpdate from "./pages/Skill/SkillsTextUpdate";
import TestimonialAdd from "./pages/Testimonial/TestimonialAdd";
import TestimonialList from "./pages/Testimonial/TestimonialList";
import Login from "./pages/User/Login";
import UserDataUpdate from "./pages/User/UserDataUpdate";
import UserSocialMediaAdd from "./pages/User/UserSocialMediaAdd";
import UserSocialMediaList from "./pages/User/UserSocialMediaList";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<Abouts />} />
            <Route path="/services" element={<Servicess />} />
            <Route path="/Portfolios" element={<Portfolios />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/" element={<PublicOutlet />}>
              <Route path="login" element={<Login />} />
            </Route>

            <Route path="/" element={<PrivateOutlet />}>
              <Route path="user/userData/update" element={<UserDataUpdate />} />
              <Route path="user/social/media/add" element={<UserSocialMediaAdd />} />
              <Route path="user/social/media/edit/:id" element={<UserSocialMediaAdd />} />
              <Route path="user/social/media/List" element={<UserSocialMediaList />} />
              <Route path="user/aboutMe/update" element={<AboutMeUpdate />} />
              <Route path="user/blog/add" element={<BlogAdd />} />
              <Route path="user/blog/list" element={<BlogList />} />
              <Route path="user/education/add" element={<EducationAdd />} />
              <Route path="user/education/list" element={<EducationList />} />
              <Route path="user/education/edit/:id" element={<EducationAdd />} />
              <Route path="user/experience/add" element={<ExperienceAdd />} />
              <Route path="user/experience/list" element={<ExperienceList />} />
              <Route path="user/experience/edit/:id" element={<ExperienceAdd />} />
              <Route path="user/service/add" element={<ServicesAdd />} />
              <Route path="user/service/list" element={<ServiceList />} />
              <Route path="user/service/edit/:id" element={<ServicesAdd />} />
              <Route path="user/skill/add" element={<SkillsAdd />} />
              <Route path="user/skill/list" element={<SkillList />} />
              <Route path="user/skill/edit/:id" element={<SkillsAdd />} />
              <Route path="user/skill/text/update" element={<SkillsTextUpdate />} />
              <Route path="user/portfolio/add" element={<PortfolioAdd />} />
              <Route path="user/portfolio/list" element={<PortfolioList />} />
              <Route path="user/portfolio/edit/:id" element={<PortfolioAdd />} />
              <Route path="user/portfolio/tag/add" element={<PortfolioTagAdd />} />
              <Route path="user/portfolio/tag/list" element={<PortfolioTagList />} />
              <Route path="user/portfolio/tag/edit/:id" element={<PortfolioTagAdd />} />
              <Route path="user/testimonial/add" element={<TestimonialAdd />} />
              <Route path="user/testimonial/list" element={<TestimonialList />} />
              <Route path="user/testimonial/edit/:id" element={<TestimonialAdd />} />
              <Route path="user/contact/list" element={<ContactList />} />
              <Route path="user/contact/address/update" element={<ContactAddressUpdate />} />
              <Route path="user/service/icon/add" element={<ServiceIconAdd />} />
              <Route path="user/service/icon/list" element={<ServiceIconList />} />
              <Route path="user/service/icon/edit/:id" element={<ServiceIconAdd />} />
            </Route>
          </Routes>
        </Content>
      </AuthProvider>
    </Router>
  );
}

export default App;
