import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useGetSkillTextQuery,
  useUpdateSkillTextMutation,
} from "../../features/skill/skillApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function SkillsTextUpdate() {
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();

  const { data: getSkillTextData, isSuccess } = useGetSkillTextQuery();
  const [
    addSkillText,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateSkillTextMutation();
  useEffect(() => {
    if (isSuccess && getSkillTextData?.id) {
      setDescription(getSkillTextData?.description);
    }
  }, [isSuccess, getSkillTextData]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("SkillsText Update Successfully");
      navigate("/user/skillText/list");
    } else if (updatedIsError) {
      toast.error("SkillsText Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (description) {
      addSkillText({ description });
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="SkillsText"
      data-nav-tooltip="Skills Text Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="SkillsText" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Description">
            <textarea
              name="Description"
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              placeholder="Description"
              className={`form-control ${
                error ? (!description ? "invalid" : "") : ""
              }`}
              value={description}
              rows="3"
            ></textarea>
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
