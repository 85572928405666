import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const portfolioApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortfolio: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("portfolio");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Portfolio"],
    }),
    getPortfolioSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`portfolio/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addPortfolio: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("portfolio", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Portfolio"],
    }),
    updatePortfolio: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`portfolio/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Portfolio"],
    }),
    deletePortfolio: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`portfolio`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Portfolio"],
    }),

    getPortfolioTag: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("portfolioTag");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["PortfolioTag"],
    }),
    getPortfolioTagSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getCollection(`portfolioTag/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addPortfolioTag: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("portfolioTag", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["PortfolioTag"],
    }),
    updatePortfolioTag: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`portfolioTag/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["PortfolioTag"],
    }),
    deletePortfolioTag: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`portfolioTag`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["PortfolioTag"],
    }),
  }),
});

export const {
  useGetPortfolioQuery,
  useGetPortfolioSingleMutation,
  useAddPortfolioMutation,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useGetPortfolioTagQuery,
  useGetPortfolioTagSingleMutation,
  useAddPortfolioTagMutation,
  useUpdatePortfolioTagMutation,
  useDeletePortfolioTagMutation,
} = portfolioApi;
