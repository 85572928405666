import SkillTableRow from "../../components/Skill/SkillTableRow";
import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import { useGetSkillsQuery } from "../../features/skill/skillApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function SkillList() {
  const { height } = useWindowDimensions();

  const {
    data: SkillData,
    isLoading,
    isError,
    isSuccess,
  } = useGetSkillsQuery();

  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && SkillData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && SkillData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Title</th>
            <th>percentage</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {SkillData.map((skill) => (
            <SkillTableRow key={skill?.id} skill={skill} />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
    <section
      id="skill"
      data-nav-tooltip="Skill"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">{content}</div>
        </div>
      </div>
    </section>
  );
}
