import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteContactMutation } from "../../features/contact/contactApi";
import swalWithBootstrapButtons from "../../hooks/swalWithBootstrapButtons";

export default function ContactTableRow({ contact = {} }) {
  const { id, name, email, subject, message } = contact || {};
  const [
    deleteContact,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeleteContactMutation();

  const handleDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          deleteContact(id);
        }
      });
  };

  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      swalWithBootstrapButtons.fire(
        "Deleted!",
        "Contact Delete Successfully.",
        "success"
      );
    } else if (deletedIsError) {
      swalWithBootstrapButtons.fire(
        "Error",
        "Contact Delete Error! :)",
        "error"
      );
    }
  }, [deletedIsError, deletedIsSuccess]);

  return (
    <tr className="text-center">
      <td>{name}</td>
      <td>{email}</td>
      <td>{subject}</td>
      <td>{message}</td>
      <td>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
