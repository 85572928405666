import { useGetEducationQuery } from "../../features/education/educationApi";

export default function Education() {
  const {
    data: educationData,
    isError,
    isLoading,
    isSuccess,
  } = useGetEducationQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
    // } else if (!isLoading && isError) {
    //   content = <Error message="There is some problem" />;
    // } else if (isSuccess && educationData?.length === 0) {
    //   content = <Error message="No education found" />;
  } else if (isSuccess && educationData?.length > 0) {
    content = educationData.map((education) => (
      <li key={education?.id}>
        <span>
          {education?.startYear}-{education?.endYear}
        </span>
        <h6>{education?.title}</h6>
        <p>{education?.institute}</p>
      </li>
    ));
  }
  return <ul className="aducation-box">{content}</ul>;
}
