import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserData: builder.query({
      async queryFn() {
        try {
          const data = await getSingleCollection("userData");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["UserData"],
    }),
    updateUserData: builder.mutation({
      async queryFn(data) {
        try {
          data.id = 1;
          let store = await updateCollection(`userData`, data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["UserData"],
    }),
    addSocialMedia: builder.mutation({
      async queryFn(data) {
        try {
          console.log(data);
          let store = await setCollection("socialMedia", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["SocialMedia"],
    }),
    getSocialMedia: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("socialMedia");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["SocialMedia"],
    }),
    getSocialMediaSingle: builder.mutation({
      async queryFn(id) {
        try {
          const SingleData = await getCollection(`socialMedia/${id}`);
          return SingleData;
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteSocialMedia: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`socialMedia`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["SocialMedia"],
    }),
    updateSocialMedia: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`socialMedia/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["SocialMedia"],
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useAddSocialMediaMutation,
  useGetSocialMediaQuery,
  useGetSocialMediaSingleMutation,
  useDeleteSocialMediaMutation,
  useUpdateSocialMediaMutation,
} = userApi;
