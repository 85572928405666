import { useGetAboutQuery } from "../../features/about/aboutApi";
import AboutMeImage from "./AboutMeImage";
import MediaCount from "../MediaCount";
import Error from "../ui/Error";
import { useSelector } from "react-redux";

export default function AboutMe() {
  const themeNight = useSelector((state) => state.theme) || {};
  const { data: aboutData, isLoading, isError, isSuccess } = useGetAboutQuery();
  let content = "";
  let imageContent = "";
  if (isLoading) {
    content = <p>Loading..</p>;
    imageContent = <p>Loading..</p>;
    // } else if (!isLoading && isError) {
    //   content = <Error message="There is some problem" />;
    // } else if (isSuccess && !aboutData?.id) {
    //   content = <Error message="There is some problem" />;
  } else if (isSuccess && aboutData?.id) {
    const { title, description, projects, satisfied, image, imageDark } = aboutData || {};
    content = (
      <div className="about-text">
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="row">
          <MediaCount number={projects} textStart="Projects" textEnd="Completed." />
          <MediaCount number={satisfied} textStart="Satisfied" textEnd="Clients." />
        </div>
        <div className="btn-bar">
          <a className="px-btn px-btn-theme" href="#">
            <span>Contact Me</span>
          </a>
          <a className="px-btn px-btn-theme" href="#">
            <span>Portfolio</span>
          </a>
        </div>
      </div>
    );
    imageContent = <AboutMeImage image={themeNight?.night ? imageDark : image} />;
  }
  return (
    <div className="row align-items-center justify-content-center">
      {imageContent}
      <div className="col-lg-6 m-15px-tb">
        <div className="about-info">
          <div className="title">
            <h3>About me.</h3>
          </div>
          {content}
        </div>
      </div>
    </div>
  );
}
