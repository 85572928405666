import { get, getDatabase, orderByKey, query, ref } from "firebase/database";
import app from "../../firebase";

const getCollection = async (collaction) => {
  // posts is the collection name
  const db = getDatabase(app);
  const Ref = ref(db, collaction);
  const Query = query(Ref, orderByKey());
  const querySnaphot = await get(Query);
  if (querySnaphot.exists()) {
    let data = "";
    if (querySnaphot.val().length === undefined) {
      let valuesObject = await Object.entries(querySnaphot.val());
      data = valuesObject.map((val) => {
        return val[1];
      });
    } else {
      data = await querySnaphot.val();
    }
    return { data: data };
  } else {
    return { data: [] };
  }
};
export default getCollection;
