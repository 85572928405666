import Isotope from "isotope-layout";
import { useEffect, useRef, useState } from "react";
import {
  useGetPortfolioQuery,
  useGetPortfolioTagQuery,
} from "../../features/portfolio/portfolioApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import PortfolioItem from "./PortfolioItem";
const Portfolio = () => {
  const { height } = useWindowDimensions();
  // Isotope
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const {
    data: portfolioData,
    isError,
    isLoading,
    isSuccess,
  } = useGetPortfolioQuery();
  const { data: portfolioTagData, isSuccess: tagIsSuccess } =
    useGetPortfolioTagQuery();
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-content", {
      itemSelector: ".grid-item",
      //    layoutMode: "fitRows",
      percentPosition: true,
      masonry: {
        columnWidth: ".grid-item",
      },
      animationOptions: {
        duration: 750,
        easing: "linear",
        queue: false,
      },
    });
    return () => isotope.current.destroy();
  });
  useEffect(() => {
    if (isotope.current) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey]);
  const handleFilterKeyChange = (key) => () => {
    setFilterKey(key);
  };
  const activeBtn = (value) => (value === filterKey ? "active" : "");
  let content = "";
  if (isSuccess && portfolioData?.length > 0) {
    content = portfolioData.map((portfolio) => (
      <PortfolioItem key={portfolio?.id} portfolio={portfolio} />
    ));
  }
  let tagContent = "";
  if (tagIsSuccess && portfolioTagData) {
    tagContent = portfolioTagData.map((tag) => (
      <li
        key={tag?.id}
        className={`c-pointer ${activeBtn(tag?.tag)}`}
        onClick={handleFilterKeyChange(tag?.tag)}
        data-filter={`.${tag?.tag}`}
      >
        {tag?.tag.toUpperCase()}
      </li>
    ));
  }
  return (
    <section
      id="work"
      data-nav-tooltip="Work"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <div className="container">
        <div className="title">
          <h3>My Portfolio.</h3>
        </div>
        <div className="portfolio-filter-01">
          <ul className="filter nav">
            <li
              className={`c-pointer ${activeBtn("*")}`}
              onClick={handleFilterKeyChange("*")}
              data-filter="*"
            >
              All
            </li>
            {tagContent}
          </ul>
        </div>
        {/* Portfolio Filter */}
        <div className="portfolio-content grid-gutter-lg grid-col-3 lightbox-gallery">
          {content}
        </div>
      </div>
    </section>
  );
};
export default Portfolio;
