import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDeletePortfolioTagMutation } from "../../features/portfolio/portfolioApi";

export default function PortfolioTagTableRow({ portfolioTag = {} }) {
  const { tag, id } = portfolioTag || {};
  const [
    deletePortfolioTag,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeletePortfolioTagMutation();

  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      toast.info("Portfolio Tag Delete Successfully.");
    } else if (deletedIsError) {
      toast.error("Portfolio Tag Delete Error!");
    }
  }, [deletedIsError, deletedIsSuccess]);

  const handleDelete = () => {
    deletePortfolioTag(id);
  };

  return (
    <tr key={id} className="text-center">
      <td>{id}</td>
      <td>{tag}</td>
      <td>
        <Link
          className="mx-2 btn btn-outline-success"
          to={`/user/portfolio/tag/edit/${id}`}
        >
          Edit
        </Link>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
