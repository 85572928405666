import { apiSlice } from "../api/apiSlice";
import getSingleCollection from "../firebase/getSingleCollection";
import updateCollection from "../firebase/updateCollection";

export const aboutApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAbout: builder.query({
      async queryFn() {
        try {
          const data = await getSingleCollection("about");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["About"],
    }),
    updateAboutMe: builder.mutation({
      async queryFn(data) {
        try {
          data.id = 1;
          let update = await updateCollection(`about`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["About"],
    }),
  }),
});

export const { useGetAboutQuery, useUpdateAboutMeMutation } = aboutApi;
