import { getDatabase, ref, set } from "firebase/database";
import app from "../../firebase";
import getCollection from "./getCollection";
const setCollection = async (collaction, data) => {
  const querySnaphot = await getCollection(collaction);
  let id = 1;
  try {
    if (querySnaphot?.data.length > 0) {
      id = Object.keys(querySnaphot.data)[
        Object.keys(querySnaphot.data).length - 1
      ];
      id = Number(id) + 1;
    }
  } catch (e) {}
  let url = collaction + "/" + id;
  data.id = id;
  // posts is the collection name
  const db = getDatabase(app);
  const Ref = ref(db, url);
  let store = set(Ref, data)
    .then(() => {
      return data;
    })
    .catch((error) => {
      return error;
    });
  return { data: store };
};
export default setCollection;
