import { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteTestimonialMutation } from "../../features/testimonials/testimonialsApi";
import swalWithBootstrapButtons from "../../hooks/swalWithBootstrapButtons";

export default function TestimonialTableRow({ testimonial = {} }) {
  const { id, name, institute, position, description, image } =
    testimonial || {};
  const [
    deleteTestimonial,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeleteTestimonialMutation();

  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      swalWithBootstrapButtons.fire(
        "Deleted!",
        "Testimonial Delete Successfully.",
        "success"
      );
    } else if (deletedIsError) {
      swalWithBootstrapButtons.fire(
        "Error",
        "Testimonial Delete Error! :)",
        "error"
      );
    }
  }, [deletedIsError, deletedIsSuccess]);

  const handleDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          deleteTestimonial(id);
        }
      });
  };

  return (
    <tr key={id} className="text-center">
      <td>{id}</td>
      <td>{name}</td>
      <td>{institute}</td>
      <td>{position}</td>
      <td>{description}</td>
      <td>
        <img src={image} title="" alt={name} width={200} />
      </td>
      <td>
        <Link
          className="mx-2 btn btn-outline-success"
          to={`/user/testimonial/edit/${id}`}
        >
          Edit
        </Link>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
