import { getDownloadURL, getStorage, ref } from "firebase/storage";
import app from "../../firebase";

export default function makeImageUrl(file, address) {
  const storage = getStorage(app);

  // Upload file and metadata to the object 'images/mountains.jpg'
  const storageRef = ref(storage, `${address}/${file.name}`);

  // Upload completed successfully, now we can get the download URL
  const imageUrl = getDownloadURL(storageRef);
  return imageUrl;
}
