import { getDatabase, ref, remove } from "firebase/database";
import app from "../../firebase";
const deleteCollection = async (collaction, id) => {
  // posts is the collection name

  const db = getDatabase(app);
  let url = collaction + `/` + id;

  const Ref = ref(db, url);
  let deleteID = await remove(Ref)
    .then(() => {
      return id;
    })
    .catch((error) => {
      return error;
    });
  return { data: deleteID };
};
export default deleteCollection;
