import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useGetContactAddressQuery,
  useUpdateContactAddressMutation,
} from "../../features/contact/contactApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ContactAddressUpdate() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const [
    updateContactAddress,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateContactAddressMutation();
  const { data: contactAddressData, isSuccess: isSuccessSingleData } =
    useGetContactAddressQuery();

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Contact Address Update Successfully");
      navigate("/contactus");
    } else if (updatedIsError) {
      toast.error("Contact Address Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && contactAddressData) {
      setTitle(contactAddressData?.title);
      setDescription(contactAddressData?.description);
      setAddress(contactAddressData?.address);
      setEmail(contactAddressData?.email);
      setPhone(contactAddressData?.phone);
    }
  }, [isSuccessSingleData, contactAddressData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && description && address && email && phone) {
      updateContactAddress({ title, description, address, email, phone });
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="ContactAddress"
      data-nav-tooltip="Contact Address Update"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="ContactAddress" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Title">
            <input
              name="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id="title"
              placeholder="Title*"
              className={`form-control ${
                error ? (!title ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Description">
            <textarea
              type="text"
              name="Description"
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              placeholder="Description"
              className={`form-control ${
                error ? (!description ? "invalid" : "") : ""
              }`}
              value={description}
              rows={4}
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="Address">
            <input
              name="Address"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              id="address"
              placeholder="Address *"
              className={`form-control ${
                error ? (!address ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Email">
            <input
              name="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder="Email *"
              className={`form-control ${
                error ? (!email ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Phone">
            <input
              name="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id="phone"
              placeholder="Phone *"
              className={`form-control ${
                error ? (!phone ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
