// import { useGetSkillsQuery, useGetSkillTextQuery } from "../../features/about/aboutApi";
import {
  useGetSkillTextQuery,
  useGetSkillsQuery,
} from "../../features/skill/skillApi";
import Skill from "./Skill";

export default function Skills() {
  const {
    data: skillTextData,
    isLoading: isLoadingText,
    isSuccess: isSuccessText,
  } = useGetSkillTextQuery();
  let contentText = "";
  if (isLoadingText) {
    contentText = <p>Loading..</p>;
  } else if (isSuccessText && skillTextData?.id) {
    const { description } = skillTextData || {};
    contentText = <p>{description}</p>;
  }

  const {
    data: skillData,
    isError,
    isLoading,
    isSuccess,
  } = useGetSkillsQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
    // } else if (!isLoading && isError) {
    //   content = <Error message="There is some problem" />;
    // } else if (isSuccess && skillData?.length === 0) {
    //   content = <Error message="There is some problem" />;
  } else if (isSuccess && skillData?.length > 0) {
    content = skillData.map((skill) => <Skill key={skill?.id} skill={skill} />);
  }
  return (
    <div className="skills-box">
      <h3>My skills</h3>
      {contentText}
      {content}
    </div>
  );
}
