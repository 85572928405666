import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useAddExperienceMutation,
  useGetExperienceSingleMutation,
  useUpdateExperienceMutation,
} from "../../features/experience/experienceApi";
import imageUpload from "../../features/firebase/imageUpload";
import makeImageUrl from "../../features/firebase/makeImageUrl";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ExperienceAdd() {
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [jobType, setJobType] = useState("");
  const [timePattern, setTimePattern] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [image, setImage] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();

  const [
    addExperience,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddExperienceMutation();
  const [
    getExperienceSingle,
    { data: experienceData, isSuccess: isSuccessSingleData },
  ] = useGetExperienceSingleMutation();
  const [
    updateExperience,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateExperienceMutation();
  const { id } = useParams();

  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Experience Insert Successfully");
      navigate("/user/experience/list");
    } else if (addIsError) {
      toast.error("Experience Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Experience Update Successfully");
      navigate("/user/experience/list");
    } else if (updatedIsError) {
      toast.error("Experience Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && experienceData?.id) {
      setTitle(experienceData?.title);
      setCompany(experienceData?.company);
      setJobType(experienceData?.jobType);
      setTimePattern(experienceData?.timePattern);
      setDescription(experienceData?.description);
      setStartDate(experienceData?.startDate);
      setEndDate(experienceData?.endDate);
      setPrevImage(experienceData?.image);
    }
  }, [isSuccessSingleData, experienceData]);

  useEffect(() => {
    if (id) {
      getExperienceSingle(id);
    }
  }, [id, getExperienceSingle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && company && jobType && timePattern) {
      let image_url = prevImage;
      if (image) {
        const image_upload = await imageUpload(image, "Experience");
        if (image_upload.state === "success")
          image_url = await makeImageUrl(image, "Experience");
      }

      if (id) {
        updateExperience({
          id,
          data: {
            title,
            company,
            jobType,
            timePattern,
            description,
            startDate,
            endDate,
            image: image_url,
          },
        });
      } else {
        addExperience({
          title,
          company,
          jobType,
          timePattern,
          description,
          startDate,
          endDate,
          image: image_url,
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="experience"
      data-nav-tooltip="experience Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="experience" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Title">
            <input
              name="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id="title"
              placeholder="Title*"
              className={`form-control ${
                error ? (!title ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Company">
            <input
              type="text"
              name="company"
              onChange={(e) => setCompany(e.target.value)}
              id="company"
              placeholder="Company"
              className={`form-control ${
                error ? (!company ? "invalid" : "") : ""
              }`}
              value={company}
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="Job Type">
            <input
              name="jobType"
              onChange={(e) => setJobType(e.target.value)}
              value={jobType}
              id="jobType"
              placeholder="Job Type *"
              className={`form-control ${
                error ? (!jobType ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Time Pattern">
            <input
              name="TimePattern"
              value={timePattern}
              onChange={(e) => setTimePattern(e.target.value)}
              id="timePattern"
              placeholder="Time Pattern *"
              className={`form-control ${
                error ? (!timePattern ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="Description">
            <textarea
              name="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              placeholder="Description *"
              className={`form-control ${
                error ? (!description ? "invalid" : "") : ""
              }`}
              rows={4}
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="Start Date">
            <input
              name="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              id="startDate"
              placeholder="Start Date *"
              className={`form-control ${
                error ? (!startDate ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="End Date">
            <input
              name="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              id="endDate"
              placeholder="End Date *"
              className={`form-control ${
                error ? (!endDate ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Image">
            <input
              type="file"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
              id="image"
              placeholder="Image *"
              className={`form-control ${
                error ? (!image ? "invalid" : "") : ""
              }`}
            />
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
