import { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteSkillMutation } from "../../features/skill/skillApi";
import swalWithBootstrapButtons from "../../hooks/swalWithBootstrapButtons";

export default function SkillTableRow({ skill = {} }) {
  const { id, title, percentage } = skill || {};
  const [
    deleteSkill,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeleteSkillMutation();

  console.log(deletedIsSuccess);

  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      swalWithBootstrapButtons.fire(
        "Deleted!",
        "Skill Delete Successfully.",
        "success"
      );
    } else if (deletedIsError) {
      swalWithBootstrapButtons.fire("Error", "Skill Delete Error! :)", "error");
    }
  }, [deletedIsError, deletedIsSuccess]);

  const handleDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          deleteSkill(id);
        }
      });
  };

  return (
    <tr key={id} className="text-center">
      <td>{title}</td>
      <td>{percentage}</td>
      <td>
        <Link
          className="mx-2 btn btn-outline-success"
          to={`/user/skill/edit/${id}`}
        >
          Edit
        </Link>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
