import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const contactApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContact: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("contact");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Contact"],
    }),
    addContact: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("contact", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Contact"],
    }),
    deleteContact: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`contact`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Contact"],
    }),
    getContactAddress: builder.query({
      async queryFn() {
        try {
          const data = await getSingleCollection(`contactAddress`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    updateContactAddress: builder.mutation({
      async queryFn(data) {
        try {
          let update = await updateCollection(`contactAddress`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetContactQuery,
  useAddContactMutation,
  useDeleteContactMutation,
  useGetContactAddressQuery,
  useUpdateContactAddressMutation,
} = contactApi;
