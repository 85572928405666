import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  night: true,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    themeChange: (state, actions) => {
      state.night = actions.payload;
    },
  },
});

export const { themeChange } = themeSlice.actions;
export default themeSlice.reducer;
