import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const educationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEducation: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("education");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Education"],
    }),
    getEducationSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`/education/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addEducation: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("education", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Education"],
    }),
    updateEducation: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`education/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Education"],
    }),
    deleteEducation: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`education`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Education"],
    }),
  }),
});

export const { useGetEducationQuery, useAddEducationMutation, useDeleteEducationMutation, useGetEducationSingleMutation, useUpdateEducationMutation } = educationApi;
