import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FormContainer from "../FormContainer";
import FormGroup from "../FromGroup";
export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const { height } = useWindowDimensions();

  const { login, signup } = useAuth();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // do validation
    try {
      setError("");
      setLoading(true);
      await login(email, password);
      // let a = await signup(email, password);
      // console.log(a);
      navigate("/user/userData/update");
    } catch (err) {
      setLoading(false);
      setError("Incorect Email or Password");
    }
  };
  return (
    <section id="aboutMe" data-nav-tooltip="About Me Entry" className="pp-section pp-scrollable section dark-bg" style={{ height: `${height}px` }}>
      <FormContainer id="login" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Email">
            <input required type="text" name="Email" id="email" placeholder="Enter Email" className={`form-control ${error ? (!email ? "invalid" : "") : ""}`} value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Password">
            <input required type="password" name="Password" id="password" placeholder="Enter password" className={`form-control ${error ? (!password ? "invalid" : "") : ""}`} value={password} onChange={(e) => setPassword(e.target.value)} />
          </FormGroup>
        </div>
        <div className="text-center col-md-12">
          <div className="send">
            <input className="px-btn px-btn-theme" type="submit" value="Submit" />
          </div>
        </div>
        <div className="info">{/* Don't have an account? <Link to="/signup">Signup</Link> instead. */}</div>
        {error && (
          <p className="error" style={{ marginTop: "10px" }}>
            {error}
          </p>
        )}
      </FormContainer>
    </section>
  );
}
