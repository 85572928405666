import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import imageUpload from "../../features/firebase/imageUpload";
import makeImageUrl from "../../features/firebase/makeImageUrl";
import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from "../../features/user/userApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FormGroup from "../FromGroup";

export default function UserDataForm() {
  const { height } = useWindowDimensions();
  const [firstTitle, setFirstTitle] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [secondTitle, setSecondTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [profile, setProfile] = useState("");
  const [cv, setCv] = useState("");
  const [prevCv, setPrevCv] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [
    updateUserData,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateUserDataMutation();
  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("UserData Update Successfully");
      navigate("/");
    } else if (updatedIsError) {
      toast.error("UserData Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  const { data: userData, isSuccess: getUserIsSuccess } = useGetUserDataQuery();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (firstTitle && name && title && secondTitle && description) {
      let image_url = imageUrl;
      if (image) {
        const image_upload = await imageUpload(image, "User");
        if (image_upload.state === "success")
          image_url = await makeImageUrl(image, "User");
      }
      let profile_url = profileUrl;
      if (profile) {
        const profile_upload = await imageUpload(profile, "User");
        if (profile_upload.state === "success")
          profile_url = await makeImageUrl(profile, "User");
      }

      let cv_url = prevCv;
      if (cv) {
        const cv_upload = await imageUpload(cv, "User");
        if (cv_upload.state === "success")
          cv_url = await makeImageUrl(cv, "User");
      }
      updateUserData({
        firstTitle,
        name,
        title,
        secondTitle,
        description,
        image: image_url,
        profilePicture: profile_url,
        cv: cv_url,
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (getUserIsSuccess && userData?.id) {
      setFirstTitle(userData?.firstTitle);
      setName(userData?.name);
      setTitle(userData?.title);
      setSecondTitle(userData?.secondTitle);
      setDescription(userData?.description);
      setImageUrl(userData?.image);
      setProfileUrl(userData?.profilePicture);
      setPrevCv(userData?.cv);
    }
  }, [getUserIsSuccess, userData]);
  return (
    <section
      id="homeBanner"
      data-nav-tooltip="Home Banner Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <div className="container">
        <form id="homeBanner-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-10">
              <FormGroup title="First Title">
                <input
                  name="FirstTitle"
                  onChange={(e) => setFirstTitle(e.target.value)}
                  value={firstTitle}
                  id="firstTitle"
                  placeholder="First Title *"
                  className={`form-control ${
                    error ? (!firstTitle ? "invalid" : "") : ""
                  }`}
                  type="text"
                />
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Name">
                <input
                  name="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  placeholder="Name*"
                  className={`form-control ${
                    error ? (!name ? "invalid" : "") : ""
                  }`}
                  type="text"
                />
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Title">
                <input
                  name="Title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  id="title"
                  placeholder="Title*"
                  className={`form-control ${
                    error ? (!title ? "invalid" : "") : ""
                  }`}
                  type="text"
                />
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Animated Title">
                <input
                  name="AnimatedTitle"
                  onChange={(e) => setSecondTitle(e.target.value)}
                  value={secondTitle}
                  id="animatedTitle"
                  placeholder="frist, second, third"
                  className={`form-control ${
                    error ? (!secondTitle ? "invalid" : "") : ""
                  }`}
                  type="text"
                />
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Description">
                <textarea
                  name="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  id="description"
                  placeholder="Description"
                  className={`form-control ${
                    error ? (!description ? "invalid" : "") : ""
                  }`}
                  value={description}
                  rows="3"
                ></textarea>
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Image">
                <input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                  id="image"
                  placeholder="Image"
                  className={`form-control ${
                    error ? (!image ? "invalid" : "") : ""
                  }`}
                />
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Profile Picture">
                <input
                  type="file"
                  onChange={(e) => setProfile(e.target.files[0])}
                  id="profile"
                  placeholder="Profile"
                  className={`form-control ${
                    error ? (!profile ? "invalid" : "") : ""
                  }`}
                />
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="CV">
                <input
                  type="file"
                  onChange={(e) => setCv(e.target.files[0])}
                  id="cv"
                  placeholder="CV"
                  className={`form-control ${
                    error ? (!cv ? "invalid" : "") : ""
                  }`}
                />
              </FormGroup>
            </div>
            <div className="text-center col-md-12">
              <div className="send">
                <input
                  className="px-btn px-btn-theme"
                  type="submit"
                  value="Submit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
