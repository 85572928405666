import { lazy } from "react";
import { useGetServicesQuery } from "../../features/services/servicesApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Service from "./Service";

const Testimonials = lazy(() => import("../Testimonial/Testimonials"), {
  ssr: false,
});
const Services = () => {
  const { height } = useWindowDimensions();

  const { data: servicesData, isError, isLoading, isSuccess } = useGetServicesQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
    // } else if (!isLoading && isError) {
    //   content = <Error message="There is some problem" />;
    // } else if (isSuccess && servicesData?.length === 0) {
    //   content = <Error message="There is some problem" />;
  } else if (isSuccess && servicesData?.length > 0) {
    content = servicesData.map((service) => <Service key={service?.id} service={service} />);
  }
  return (
    <section id="services" data-nav-tooltip="Services" className="pp-section pp-scrollable section" style={{ height: `${height}px` }}>
      <div className="container">
        <div className="title">
          <h3>What I do?</h3>
        </div>
        <div className="row">{content}</div>
        <div className="separated" />
        <Testimonials />
      </div>
    </section>
  );
};
export default Services;
