export default function FormGroup({ title = "", children }) {
  return (
    <div className="form-group row">
      <div className="col-sm-4 text-sm-right">
        <label className="form-label h5 mt-1">{title && title + ":"}</label>
      </div>
      <div className="col-sm-8 text-black row">{children}</div>
    </div>
  );
}
