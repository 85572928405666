import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormGroup from "../../components/FromGroup";
import {
  useAddSocialMediaMutation,
  useGetSocialMediaSingleMutation,
  useUpdateSocialMediaMutation,
} from "../../features/user/userApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function UserSocialMediaAdd() {
  const { height } = useWindowDimensions();

  const [link, setLink] = useState("");
  const [icon, setIcon] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [
    addSocialMedia,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddSocialMediaMutation();
  const [
    getSocialMediaSingle,
    { data: SocialMediaData, isSuccess: isSuccessSingleData },
  ] = useGetSocialMediaSingleMutation();
  const [
    updateSocialMedia,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateSocialMediaMutation();
  const { id } = useParams();

  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Social Media Insert Successfully");
      navigate("/user/social/media/list");
    } else if (addIsError) {
      toast.error("Social Media Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Social Media Update Successfully");
      navigate("/user/social/media/list");
    } else if (updatedIsError) {
      toast.error("Social Media Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && SocialMediaData?.id) {
      setLink(SocialMediaData?.link);
      setIcon(SocialMediaData?.icon);
    }
  }, [isSuccessSingleData, SocialMediaData]);

  useEffect(() => {
    if (id) {
      getSocialMediaSingle(id);
    }
  }, [id, getSocialMediaSingle]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (link && icon) {
      if (id) {
        updateSocialMedia({
          id,
          data: {
            link,
            icon,
          },
        });
      } else {
        addSocialMedia({
          link,
          icon,
        });
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {}, []);
  return (
    <section
      id="homeBanner"
      data-nav-tooltip="Home Banner Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <div className="container">
        <form id="homeBanner-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-10">
              <FormGroup title="Icon">
                <select
                  name="icon"
                  value={icon}
                  onChange={(e) => setIcon(e.target.value)}
                  id="icon"
                  className={`form-control ${
                    error ? (!icon ? "invalid" : "") : ""
                  }`}
                >
                  <option value="">Select</option>
                  <option value='<i className="fab fa-facebook-f" />'>
                    Facebook
                  </option>
                  <option value='<i className="fab fa-twitter" />'>
                    Twitter
                  </option>
                  <option value='<i className="fab fa-instagram" />'>
                    Instagram
                  </option>
                  <option value='<i className="fab fa-linkedin-in" />'>
                    Linkedin
                  </option>
                  <option value='<i className="fab fa-pinterest" />'>
                    Pinterest
                  </option>
                  <option value='<i class="fab fa-reddit"></i>'>Reddit</option>
                  <option value='<i class="fab fa-youtube"></i>'>
                    Youtube
                  </option>
                  <option value='<i class="fab fa-tumblr"></i>'>Tumblr</option>
                  <option value='<i class="fab fa-tiktok"></i>'>TikTok</option>
                  <option value='<i class="fab fa-quora"></i>'>Quora</option>
                  <option value='<i class="fab fa-flickr"></i>'>Flickr</option>
                  <option value='<i class="fab fa-meetup"></i>'>Meetup</option>
                  <option value='<i class="fab fa-foursquare"></i>'>
                    Forsquare
                  </option>
                  <option value='<i class="fab fa-xing"></i>'>Xing</option>
                  <option value='<i class="fab fa-snapchat-square"></i>'>
                    Snspchat
                  </option>
                </select>
              </FormGroup>
            </div>
            <div className="col-md-10">
              <FormGroup title="Link">
                <input
                  name="Link"
                  onChange={(e) => setLink(e.target.value)}
                  value={link}
                  id="link"
                  placeholder="Link *"
                  className={`form-control ${
                    error ? (!link ? "invalid" : "") : ""
                  }`}
                  type="text"
                />
              </FormGroup>
            </div>

            <div className="text-center col-md-12">
              <div className="send">
                <input
                  className="px-btn px-btn-theme"
                  type="submit"
                  value="Submit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
