import PortfolioTagTableRow from "../../components/Portfolio/PortfolioTagTableRow";
import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import { useGetPortfolioTagQuery } from "../../features/portfolio/portfolioApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
export default function PortfolioTagList() {
  const { height } = useWindowDimensions();
  const {
    data: portfolioTagData,
    isLoading,
    isError,
    isSuccess,
  } = useGetPortfolioTagQuery();

  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && portfolioTagData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && portfolioTagData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Sl</th>
            <th>Tag</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {portfolioTagData.map((portfolioTag) => (
            <PortfolioTagTableRow
              key={portfolioTag?.id}
              portfolioTag={portfolioTag}
            />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
    <section
      id="portfolio"
      data-nav-tooltip="Portfolios"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">{content}</div>
        </div>
      </div>
    </section>
  );
}
