import { Fragment } from "react";
import { useGetExperienceQuery } from "../../features/experience/experienceApi";
import Experience from "./Experience";

export default function Experiences() {
  const {
    data: experienceData,
    isError,
    isLoading,
    isSuccess,
  } = useGetExperienceQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
    // } else if (!isLoading && isError) {
    //   content = <Error message="There is some problem" />;
    // } else if (isSuccess && experienceData?.length === 0) {
    //   content = <Error message="There is some problem" />;
  } else if (isSuccess && experienceData?.length > 0) {
    content = experienceData.map((experience) => (
      <Experience key={experience?.id} experience={experience} />
    ));
  }
  return (
    <Fragment>
      <div className="title">
        <h3>Experience.</h3>
      </div>
      <div className="resume-box">{content}</div>
    </Fragment>
  );
}
