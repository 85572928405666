import parse from "html-react-parser";
import { useGetSocialMediaQuery } from "../features/user/userApi";

export default function SocialMedias() {
  const { data: SocialMediaData, isSuccess } = useGetSocialMediaQuery();

  let content = "";
  if (isSuccess && SocialMediaData?.length > 0) {
    content = SocialMediaData.map((social) => {
      //   <Link to={{ pathname: social?.link }} target="_blank" key={social?.id}>
      //     {parse(social?.icon)}
      //   </Link>
      let url =
        social?.link.includes("https://") == true
          ? social.link
          : social?.link.includes("http://")
          ? social?.link
          : `https://${social?.link}`;
      return (
        <span key={social?.id}>
          {parse(`<a href=${url} target="_blank" rel="noopener noreferrer">
        ${social?.icon}
      </a>`)}
        </span>
      );
    });
  }
  return (
    <div className="nav justify-content-center social-icons">{content}</div>
  );
}
