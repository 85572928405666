import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useAddEducationMutation,
  useGetEducationSingleMutation,
  useUpdateEducationMutation,
} from "../../features/education/educationApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function EducationAdd() {
  const [title, setTitle] = useState("");
  const [institute, setInstitute] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();

  const [
    addEducation,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddEducationMutation();
  const [
    getEducationSingle,
    { data: educationData, isSuccess: isSuccessSingleData },
  ] = useGetEducationSingleMutation();
  const [
    updateEducation,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateEducationMutation();
  const { id } = useParams();
  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Education Insert Successfully");
      navigate("/user/education/list");
    } else if (addIsError) {
      toast.error("Education Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Education Update Successfully");
      navigate("/user/education/list");
    } else if (updatedIsError) {
      toast.error("Education Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);
  
  useEffect(() => {
    if (isSuccessSingleData && educationData?.id) {
      setTitle(educationData?.title);
      setInstitute(educationData?.institute);
      setStartYear(educationData?.startYear);
      setEndYear(educationData?.endYear);
    }
  }, [isSuccessSingleData, educationData]);

  useEffect(() => {
    if (id) {
      getEducationSingle(id);
    }
  }, [id, getEducationSingle]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && institute && startYear && endYear) {
      if (id) {
        updateEducation({ id, data: { title, institute, startYear, endYear } });
      } else {
        addEducation({ title, institute, startYear, endYear });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="aboutMe"
      data-nav-tooltip="About Me Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Title">
            <input
              name="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id="title"
              placeholder="Title*"
              className={`form-control ${
                error ? (!title ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Institute">
            <input
              type="text"
              name="Institute"
              onChange={(e) => setInstitute(e.target.value)}
              id="institute"
              placeholder="Institute"
              className={`form-control ${
                error ? (!institute ? "invalid" : "") : ""
              }`}
              value={institute}
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="Start Year">
            <input
              name="StartYear"
              onChange={(e) => setStartYear(e.target.value)}
              value={startYear}
              id="startYear"
              placeholder="StartYear *"
              className={`form-control ${
                error ? (!startYear ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="End Year">
            <input
              name="EndYear"
              value={endYear}
              onChange={(e) => setEndYear(e.target.value)}
              id="endYear"
              placeholder="EndYear *"
              className={`form-control ${
                error ? (!endYear ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
