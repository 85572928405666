import EducationAndSkills from "../Education&Skills";
import Experience from "../Experience/Experiences";
import AboutMe from "./AboutMe";

export default function About() {
  return (
    <section
      id="about"
      data-nav-tooltip="About"
      className="pp-section pp-scrollable section counter"
    >
      <div className="container">
        <AboutMe />
        <div className="separated" />
        <EducationAndSkills />
        <div className="separated" />
        <Experience />
      </div>
    </section>
  );
}
