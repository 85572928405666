import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Table({ children }) {
  const [textColor, setTextColor] = useState(false);
  const { night } = useSelector((state) => state.theme) || {};
  useEffect(() => {
    setTextColor(night);
  }, [night]);
  return (
    <table
      className={`table table-bordered ${
        textColor ? "text-white" : "border-dark"
      }`}
    >
      {children}
    </table>
  );
}
