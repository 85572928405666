import parse from "html-react-parser";

export default function Service({ service = {} }) {
  const { title, description, icon } = service || {};
  return (
    <div className="col-sm-6 m-15px-tb">
      <div className="feature-box-01 media">
        {parse(icon)}
        <div className="feature-content media-body">
          <h5>{title}</h5>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
