import { getDatabase, ref, update } from "firebase/database";
import app from "../../firebase";
const updateCollection = async (collaction, data) => {
  // posts is the collection name
  const db = getDatabase(app);
  const Ref = ref(db, collaction);
  let store = update(Ref, data)
    .then(() => {
      return data;
    })
    .catch((error) => {
      return error;
    });
  return { data: store };
};
export default updateCollection;
