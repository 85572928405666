import About from "../../components/About/About";
// import Blog from "../../components/Blog/Blog";
import Contact from "../../components/Contact/Contact";
import HomeBanner from "../../components/Home/HomeBanner";
import Portfolio from "../../components/Portfolio/Portfolio";
import Services from "../../components/Service/Services";
import Layout from "../../layout/Layout";

export default function Home() {
  return (
    <>
      {/* Home Banner */}
      <HomeBanner />
      {/* End Home Banner */}
      {/* about us */}
      <About />
      {/* End about us */}
      {/* Services */}
      <Services />
      {/* End Services */}
      {/* Portfolio */}
      <Portfolio />
      {/* End Portfolio */}
      {/* Blog */}
      {/* <Blog /> */}
      {/* End Blog */}
      {/* Contact us */}
      <Contact />
    </>
  );
}
