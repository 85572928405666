export default function Experience({ experience = {} }) {
  const {
    title,
    company,
    jobType,
    timePattern,
    description,
    startDate,
    endDate,
    image,
  } = experience || {};
  return (
    <div className="resume-row">
      <div className="row">
        <div className="col-sm-3 col-md-3 col-xl-2">
          <div className="rb-left">
            <img src={image} title="" alt={company} />
          </div>
        </div>
        <div className="col-sm-9 col-md-9 col-xl-10">
          <div className="rb-right">
            <h6>{title}</h6>
            <label>
              {company} | {jobType} | {startDate} - {endDate}
            </label>
            <div className="rb-time">{timePattern}</div>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
