import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useAddSkillMutation,
  useGetSkillSingleMutation,
  useUpdateSkillMutation,
} from "../../features/skill/skillApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function SkillsAdd() {
  const [title, setTitle] = useState("");
  const [percentage, setPercentage] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const [getSkillSingle, { data: skillData, isSuccess: isSuccessSingleData }] =
    useGetSkillSingleMutation();
  const [
    addSkill,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddSkillMutation();

  const [
    updateSkill,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateSkillMutation();
  const { id } = useParams();
  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Skill Insert Successfully");
      navigate("/user/skill/list");
    } else if (addIsError) {
      toast.error("Skill Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Skill Update Successfully");
      navigate("/user/skill/list");
    } else if (updatedIsError) {
      toast.error("Skill Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && skillData?.id) {
      setTitle(skillData?.title);
      setPercentage(skillData?.percentage);
    }
  }, [isSuccessSingleData, skillData]);

  useEffect(() => {
    if (id) {
      getSkillSingle(id);
    }
  }, [id, getSkillSingle]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title && percentage) {
      if (id) {
        updateSkill({ id, data: { title, percentage } });
      } else {
        addSkill({ title, percentage });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="aboutMe"
      data-nav-tooltip="About Me Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Title">
            <input
              name="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id="title"
              placeholder="Title*"
              className={`form-control ${
                error ? (!title ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Percentage">
            <input
              type="text"
              name="Percentage"
              onChange={(e) => setPercentage(e.target.value)}
              id="percentage"
              placeholder="Percentage"
              className={`form-control ${
                error ? (!percentage ? "invalid" : "") : ""
              }`}
              value={percentage}
            />
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
