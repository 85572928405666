import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useAddServicesMutation,
  useGetServicesIconQuery,
  useGetServicesSingleMutation,
  useUpdateServicesMutation,
} from "../../features/services/servicesApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ServicesAdd() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const [icon, setIcon] = useState("");

  const navigate = useNavigate();
  const {
    data: serviceIconData,
    isLoading,
    isError,
    isSuccess,
  } = useGetServicesIconQuery();
  const [
    getServiceSingle,
    { data: serviceData, isSuccess: isSuccessSingleData },
  ] = useGetServicesSingleMutation();
  const [
    addService,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddServicesMutation();
  const [
    updateService,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateServicesMutation();
  const { id } = useParams();
  // console.log(serviceIconData);
  let contentIcon = "";
  if (!isError && serviceIconData?.length > 0) {
    contentIcon = serviceIconData.map((serviceIcon) => {
      return (
        <label for={serviceIcon?.id} key={serviceIcon?.id}>
          <div className="feature-box-01">
            <input
              type="radio"
              id={serviceIcon?.id}
              value={serviceIcon?.iconTag}
              name="icon"
              onChange={(e) => setIcon(e.target.value)}
              checked={serviceIcon?.iconTag === icon}
            />
            {parse(serviceIcon?.iconTag)}
          </div>
        </label>
      );
    });
  }
  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Services Insert Successfully");
      navigate("user/service/list");
    } else if (addIsError) {
      toast.error("Services Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Services Update Successfully");
      navigate("/user/service/list");
    } else if (updatedIsError) {
      toast.error("Services Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && serviceData?.id) {
      setTitle(serviceData?.title);
      setDescription(serviceData?.description);
      setIcon(serviceData?.icon);
    }
  }, [isSuccessSingleData, serviceData]);

  useEffect(() => {
    if (id) {
      getServiceSingle(id);
    }
  }, [id, getServiceSingle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && description) {
      if (id) {
        updateService({ id, data: { title, description, icon } });
      } else {
        addService({ title, description, icon });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="aboutMe"
      data-nav-tooltip="About Me Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Title">
            <input
              name="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id="title"
              placeholder="Title*"
              className={`form-control ${
                error ? (!title ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Description">
            <input
              type="text"
              name="Description"
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              placeholder="Description"
              className={`form-control ${
                error ? (!description ? "invalid" : "") : ""
              }`}
              value={description}
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="">{contentIcon}</FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
