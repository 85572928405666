import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    "Education",
    "UserData",
    "Testimonials",
    "Skills",
    "SkillText",
    "Services",
    "Portfolio",
    "Experience",
    "About",
    "Contact",
    "SocialMedia",
    "PortfolioTag",
    "servicesIcon",
  ],
  endpoints: (builder) => ({}),
});
