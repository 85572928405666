import ExperienceTableRow from "../../components/Experience/ExperienceTableRow";
import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import { useGetExperienceQuery } from "../../features/experience/experienceApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ExperienceList() {
  const { height } = useWindowDimensions();
  const {
    data: experienceData,
    isLoading,
    isError,
    isSuccess,
  } = useGetExperienceQuery();

  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && experienceData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && experienceData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Sl</th>
            <th>Title</th>
            <th>Company</th>
            <th>Job Type</th>
            <th>Time Pattern</th>
            <th>Description</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {experienceData.map((experience) => (
            <ExperienceTableRow key={experience?.id} experience={experience} />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
      <section
        id="experience"
        data-nav-tooltip="Experiences"
        className="pp-section pp-scrollable section dark-bg"
        style={{ height: `${height}px` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">{content}</div>
          </div>
        </div>
      </section>
  );
}
