import { useEffect, useState } from "react";
import { useAddContactMutation } from "../../features/contact/contactApi";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState(null);

  const [addContact, { isSuccess }] = useAddContactMutation();

  useEffect(() => {
    if (isSuccess) clearForm();
  }, [isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.length === 0 || email.length === 0 || message.length === 0 || subject.length === 0) {
      setError(true);
      clearError();
    } else {
      addContact({ name, email, message, subject });
    }
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setSubject("");
  };

  const clearError = () => {
    setTimeout(() => {
      setError(null);
    }, 2000);
  };
  return (
    <form id="contact-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input name="name" onChange={(e) => setName(e.target.value)} value={name} id="name" placeholder="Name *" className={`form-control ${error ? (!name ? "invalid" : "") : ""}`} type="text" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input name="email" onChange={(e) => setEmail(e.target.value)} value={email} id="email" placeholder="Email *" className={`form-control ${error ? (!email ? "invalid" : "") : ""}`} type="email" />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <input name="subject" onChange={(e) => setSubject(e.target.value)} value={subject} id="subject" placeholder="Subject *" className={`form-control ${error ? (!subject ? "invalid" : "") : ""}`} type="text" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <textarea name="message" onChange={(e) => setMessage(e.target.value)} value={message} id="message" placeholder="Your message *" rows={5} className={`form-control ${error ? (!message ? "invalid" : "") : ""}`} />
          </div>
        </div>
        <div className="col-md-12">
          <div className="send">
            {/* <button
                        onSubmit={(e) => onSubmit(e)}
                        className="px-btn px-btn-theme"
                        type="button"
                        value="Send"
                      >
                        {" "}
                        send message
                      </button> */}
            <input className="px-btn px-btn-theme" type="submit" value="send message" />
          </div>
          <span
            id="suce_message"
            className="text-success"
            style={{
              display: error !== null ? (!error ? "block" : "none") : "none",
            }}
          >
            Message Sent Successfully
          </span>
          <span id="err_message" className="text-danger" style={{ display: "none" }}>
            Message Sending Failed
          </span>
        </div>
      </div>
    </form>
  );
}
