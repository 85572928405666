import { useState } from "react";
import { useDispatch } from "react-redux";
import { themeChange } from "../features/theme/themeSlice";

const DayNightMood = () => {
  const dispatch = useDispatch();
  const [night, setNight] = useState(true);
  const changeMood = () => {
    document.querySelector("body").classList.toggle("theme-light");
    dispatch(themeChange(!night));
    setNight(!night);
  };
  return (
    <label className="color_switch" onClick={() => changeMood()}>
      <i className="fas fa-moon" />
    </label>
  );
};
export default DayNightMood;
