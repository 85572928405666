import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useGetAboutQuery,
  useUpdateAboutMeMutation,
} from "../../features/about/aboutApi";
import imageUpload from "../../features/firebase/imageUpload";
import makeImageUrl from "../../features/firebase/makeImageUrl";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function AboutMeUpdate() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState("");
  const [satisfied, setSatisfied] = useState("");
  const [image, setImage] = useState("");
  const [prevImage, setPrevImage] = useState("");
  const [imageDark, setImageDark] = useState("");
  const [prevImageDark, setPrevImageDark] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();
  const [
    updateAboutMe,
    {
      isSuccess: updatedIsSuccess,
      isError: updatedIsError,
      isLoading: updatedIsLoading,
    },
  ] = useUpdateAboutMeMutation();
  const { data: aboutMe, isSuccess } = useGetAboutQuery();
  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("About Me Update Successfully");
      navigate("/about");
    } else if (updatedIsError) {
      toast.error("About Me Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (projects && satisfied && title && description) {
      let image_url = prevImage;
      if (image) {
        const image_upload = await imageUpload(image, "User");
        if (image_upload.state === "success")
          image_url = await makeImageUrl(image, "User");
      }
      let imageDark_url = prevImageDark;
      if (imageDark) {
        const image_upload = await imageUpload(imageDark, "User");
        if (image_upload.state === "success")
          imageDark_url = await makeImageUrl(imageDark, "User");
      }
      updateAboutMe({
        title,
        description,
        projects,
        satisfied,
        image: image_url,
        imageDark: imageDark_url,
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (isSuccess && aboutMe?.id) {
      setTitle(aboutMe?.title);
      setDescription(aboutMe?.description);
      setProjects(aboutMe?.projects);
      setSatisfied(aboutMe?.satisfied);
      setPrevImage(aboutMe?.image);
      setPrevImageDark(aboutMe?.imageDark);
    }
  }, [isSuccess, aboutMe]);

  return (
    <>
      <section
        id="aboutMe"
        data-nav-tooltip="About Me Entry"
        className="pp-section pp-scrollable section dark-bg"
        style={{ height: `${height}px` }}
      >
        <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
          <div className="col-md-10">
            <FormGroup title="Title">
              <input
                name="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                id="title"
                placeholder="Title*"
                className={`form-control ${
                  error ? (!title ? "invalid" : "") : ""
                }`}
                type="text"
              />
            </FormGroup>
          </div>
          <div className="col-md-10">
            <FormGroup title="Description">
              <textarea
                name="Description"
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                placeholder="Description"
                className={`form-control ${
                  error ? (!description ? "invalid" : "") : ""
                }`}
                value={description}
                rows="3"
              ></textarea>
            </FormGroup>
          </div>

          <div className="col-md-10">
            <FormGroup title="Projects Completed">
              <input
                name="Projects"
                onChange={(e) => setProjects(e.target.value)}
                value={projects}
                id="projects"
                placeholder="Projects *"
                className={`form-control ${
                  error ? (!projects ? "invalid" : "") : ""
                }`}
                type="text"
              />
            </FormGroup>
          </div>
          <div className="col-md-10">
            <FormGroup title="Satisfied Clients">
              <input
                name="Satisfied"
                value={satisfied}
                onChange={(e) => setSatisfied(e.target.value)}
                id="satisfied"
                placeholder="Satisfied*"
                className={`form-control ${
                  error ? (!satisfied ? "invalid" : "") : ""
                }`}
                type="text"
              />
            </FormGroup>
          </div>
          <div className="col-md-10">
            <FormGroup title="Image For Night Mode">
              <input
                type="file"
                onChange={(e) => setImageDark(e.target.files[0])}
                id="imageDark"
                placeholder="Image Dark"
                className={`form-control ${
                  error ? (!imageDark ? "invalid" : "") : ""
                }`}
              />
            </FormGroup>
          </div>
          <div className="col-md-10">
            <FormGroup title="Image For Light Mode">
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                id="image"
                placeholder="Image"
                className={`form-control ${
                  error ? (!image ? "invalid" : "") : ""
                }`}
              />
            </FormGroup>
          </div>
          <div className="text-center col-md-12">
            <div className="send">
              <input
                className="px-btn px-btn-theme"
                type="submit"
                value="Submit"
              />
            </div>
          </div>
        </FormContainer>
      </section>
    </>
  );
}
