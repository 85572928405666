import { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SocialMedias from "../components/SocialMedias";
import useDocumentTitle from "../components/useDocumentTitle";
import { useAuth } from "../contexts/AuthContext";
import { useGetUserDataQuery } from "../features/user/userApi";

const Header = () => {
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const { data: userData, isSuccess: getUserIsSuccess } = useGetUserDataQuery();
  useDocumentTitle(name);
  const [sideBarToggle, setSideBarToggle] = useState(false);
  useEffect(() => {
    if (getUserIsSuccess && userData?.id) {
      setProfile(userData?.profilePicture);
      setName(userData?.name);
    }
  }, [getUserIsSuccess, userData]);
  const { currentUser } = useAuth();

  return (
    <>
      <div className="mob-header">
        <div className="d-flex">
          <div className="navbar-brand">
            <Link to="/">
              <span className="logo-text">{name}</span>
            </Link>
          </div>
          <button className={`toggler-menu ${sideBarToggle ? "open" : ""}`} onClick={() => setSideBarToggle(!sideBarToggle)}>
            <span />
            <span />
            <span />
          </button>
        </div>
      </div>
      {/* End Header */}
      {/* nav bar */}
      <header className={`header-left ${sideBarToggle ? "menu-open menu-open-desk" : ""}`}>
        <div className="scroll-bar">
          <div className="hl-top">
            <div className="hl-logo">
              <div className="img">
                <img src={profile} title="" alt={name} />
              </div>
              <h5>{name}</h5>
            </div>
          </div>
          {currentUser ? <MenuWithBlog /> : <MenuWithOutBlog />}
        </div>
        <SocialMedias />
      </header>
    </>
  );
};
export default Header;

const MenuWithOutBlog = () => {
  return (
    <div className="sidebar-menu">
      <ul className="nav nav-menu" id="pp-menu">
        <li data-menuanchor="home" className="active header-menu">
          <Link className="nav-link" to="/">
            <i className="ti-home" />
            <span>Home</span>
          </Link>
        </li>
        <li data-menuanchor="about" className="header-menu">
          <Link className="nav-link" to="/about">
            <i className="ti-id-badge" />
            <span>About Me</span>
          </Link>
        </li>
        <li data-menuanchor="services" className="header-menu">
          <Link className="nav-link" to="/services">
            <i className="ti-panel" />
            <span>Services</span>
          </Link>
        </li>
        <li data-menuanchor="Portfolios" className="header-menu">
          <Link className="nav-link" to="/Portfolios">
            <i className="ti-bookmark-alt" />
            <span>Portfolio</span>
          </Link>
        </li>
        {/* <li data-menuanchor="blog" className="blog header-menu">
          <Link className="nav-link" to="/blog">
            <i className="ti-layout-media-overlay-alt-2" />
            <span>Blogs</span>
          </Link>
        </li> */}
        <li data-menuanchor="contactus" className="header-menu">
          <Link className="nav-link" to="/contactus">
            <i className="ti-map-alt" />
            <span>Contact Me</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

const MenuWithBlog = () => {
  const { logout } = useAuth();
  const themeNight = useSelector((state) => state.theme) || {};
  return (
    <div className="sidebar-menu">
      <Sidebar>
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  backgroundColor: themeNight?.night ? "#0c0c14" : "#f7f7ff",
                  "&:hover": {
                    backgroundColor: themeNight?.night ? "#0c0c14" : "#f7f7ff",
                  },
                };
              if (level === 1)
                return {
                  backgroundColor: themeNight?.night ? "#0c0c14" : "#f7f7ff",
                  "&:hover": {
                    backgroundColor: themeNight?.night ? "#0c0c14" : "#f7f7ff",
                  },
                };
            },
          }}
        >
          <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/" />}>
            Home
          </MenuItem>
          <SubMenu label="User" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/userData/update" />}>
              User Data Update
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/social/media/add" />}>
              Social Media Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/social/media/List" />}>
              Social Media List
            </MenuItem>
          </SubMenu>
          <SubMenu label="About" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/about" />}>
              About Me
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/aboutMe/update" />}>
              About Me Update
            </MenuItem>
          </SubMenu>
          <SubMenu label="Services" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/services" />}>
              Services
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/service/add" />}>
              Services Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/service/list" />}>
              Services List
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/service/icon/add" />}>
              Services Icon Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/service/icon/list" />}>
              Services Icon List
            </MenuItem>
          </SubMenu>
          <SubMenu label="Portfolio" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/Portfolios" />}>
              Portfolio
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/portfolio/add" />}>
              Portfolio Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/portfolio/list" />}>
              Portfolio List
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/portfolio/tag/add" />}>
              Portfolio Tag Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/portfolio/tag/list" />}>
              Portfolio Tag List
            </MenuItem>
          </SubMenu>

          <SubMenu label="Education" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/education/add" />}>
              Education Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/education/list" />}>
              Education List
            </MenuItem>
          </SubMenu>
          <SubMenu label="Experience" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/experience/add" />}>
              Experience Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/experience/list" />}>
              Experience List
            </MenuItem>
          </SubMenu>

          <SubMenu label="Skill" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/skill/add" />}>
              Skill Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/skill/list" />}>
              Skill List
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/skill/text/update" />}>
              Skill Text
            </MenuItem>
          </SubMenu>
          <SubMenu label="Testimonial" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/testimonial/add" />}>
              Testimonial Add
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/testimonial/list" />}>
              Testimonial List
            </MenuItem>
          </SubMenu>
          <SubMenu label="Contact" icon={<i className="ti-home" />}>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/contactus" />}>
              Contact Me
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/contact/list" />}>
              Contact List
            </MenuItem>
            <MenuItem icon={<i className="ti-map-alt" />} component={<Link to="/user/contact/address/update" />}>
              Contact Address Update
            </MenuItem>
          </SubMenu>
          <MenuItem icon={<i className="ti-map-alt" />} onClick={logout}>
            Logout
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};
