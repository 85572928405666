export default function AboutMeImage({ image = "" }) {
  return (
    <div className="col-lg-6 m-15px-tb">
      <div className="about-me">
        <div className="img">
          <div className="img-in">
            <img src={image} title="" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
