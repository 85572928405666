import { get, getDatabase, orderByKey, query, ref } from "firebase/database";
import app from "../../firebase";

const getSingleCollection = async (collaction) => {
  // posts is the collection name
  const db = getDatabase(app);
  const Ref = ref(db, collaction);
  const Query = query(Ref, orderByKey());
  const querySnaphot = await get(Query);
  if (querySnaphot.exists()) {
    return { data: querySnaphot.val() };
  } else {
    return { data: [] };
  }
};
export default getSingleCollection;
