import { Fragment } from "react";
import Skills from "./Skill/Skills";
import Education from "./Education/Education";
const EducationAndSkills = () => {
  return (
    <Fragment>
      <div className="title">
        <h3>Education &amp; Skills</h3>
      </div>
      <div className="row">
        <div className="col-lg-4 m-15px-tb">
          <Education />
        </div>
        <div className="col-lg-7 ml-auto m-15px-tb">
          <Skills />
        </div>
      </div>
    </Fragment>
  );
};
export default EducationAndSkills;
