import Table from "../../components/Table";
import TestimonialTableRow from "../../components/Testimonial/TestimonialTableRow";
import Error from "../../components/ui/Error";
import { useGetTestimonialsQuery } from "../../features/testimonials/testimonialsApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
export default function TestimonialList() {
  const { height } = useWindowDimensions();

  const { data: TestimonialData, isLoading, isError, isSuccess } = useGetTestimonialsQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && TestimonialData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && TestimonialData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Sl</th>
            <th>Name</th>
            <th>Institute</th>
            <th>Position</th>
            <th>Description</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {TestimonialData.map((testimonial) => (
            <TestimonialTableRow key={testimonial?.id} testimonial={testimonial} />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
      <section id="testimonial" data-nav-tooltip="Skill" className="pp-section pp-scrollable section dark-bg" style={{ height: `${height}px` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">{content}</div>
          </div>
        </div>
      </section>
  );
}
