import { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDeleteExperienceMutation } from "../../features/experience/experienceApi";
import swalWithBootstrapButtons from "../../hooks/swalWithBootstrapButtons";

export default function ExperienceTableRow({ experience = {} }) {
  const {
    id,
    title,
    company,
    jobType,
    timePattern,
    description,
    startDate,
    endDate,
    image,
  } = experience || {};
  const [
    deleteExperience,
    { isSuccess: deletedIsSuccess, isError: deletedIsError },
  ] = useDeleteExperienceMutation();

  useEffect(() => {
    if (!deletedIsError && deletedIsSuccess) {
      swalWithBootstrapButtons.fire(
        "Deleted!",
        "Experience Delete Successfully.",
        "success"
      );
    } else if (deletedIsError) {
      swalWithBootstrapButtons.fire(
        "Error",
        "Experience Delete Error! :)",
        "error"
      );
    }
  }, [deletedIsError, deletedIsSuccess]);

  const handleDelete = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          deleteExperience(id);
        }
      });
  };

  return (
    <tr className="text-center">
      <td>{id}</td>
      <td>{title}</td>
      <td>{company}</td>
      <td>{jobType}</td>
      <td>{timePattern}</td>
      <td>{description}</td>
      <td>{startDate}</td>
      <td>{endDate}</td>
      <td>
        <img src={image} title="" alt={company} width={200} />
      </td>
      <td>
        <Link
          className="mx-2 btn btn-outline-success"
          to={`/user/experience/edit/${id}`}
        >
          Edit
        </Link>
        <button className="mx-2 btn btn-outline-danger" onClick={handleDelete}>
          Delete
        </button>
      </td>
    </tr>
  );
}
