import ServiceIconTableRow from "../../components/Service/ServiceIconTableRow";
import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import { useGetServicesIconQuery } from "../../features/services/servicesApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ServiceIconList() {
  const { height } = useWindowDimensions();
  const {
    data: serviceData,
    isLoading,
    isError,
    isSuccess,
  } = useGetServicesIconQuery();
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && serviceData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && serviceData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Icon</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {serviceData.map((service) => (
            <ServiceIconTableRow key={service?.id} service={service} />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
    <section
      id="service"
      data-nav-tooltip="Service"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">{content}</div>
        </div>
      </div>
    </section>
  );
}
