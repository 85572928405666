import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import {
  useAddPortfolioTagMutation,
  useGetPortfolioTagSingleMutation,
  useUpdatePortfolioTagMutation,
} from "../../features/portfolio/portfolioApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function PortfolioTagAdd() {
  const [tag, setTag] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();

  const [
    getPortfolioTagSingle,
    { data: portfolioData, isSuccess: isSuccessSingleData },
  ] = useGetPortfolioTagSingleMutation();
  const [
    addPortfolioTag,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddPortfolioTagMutation();

  const [
    updatePortfolioTag,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdatePortfolioTagMutation();

  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Portfolio Insert Successfully");
      navigate("/user/portfolio/list");
    } else if (addIsError) {
      toast.error("Portfolio Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Portfolio Update Successfully");
      navigate("/user/portfolio/list");
    } else if (updatedIsError) {
      toast.error("Portfolio Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  const { id } = useParams();

  useEffect(() => {
    if (isSuccessSingleData && portfolioData?.id) {
      setTag(portfolioData?.tag);
    }
  }, [isSuccessSingleData, portfolioData]);

  useEffect(() => {
    if (id) {
      getPortfolioTagSingle(id);
    }
  }, [id, getPortfolioTagSingle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tag) {
      if (id) {
        updatePortfolioTag({
          id,
          data: { tag },
        });
      } else {
        addPortfolioTag({ tag });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="aboutMe"
      data-nav-tooltip="About Me Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="aboutMe" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Tag">
            <input
              type="text"
              name="Tag"
              onChange={(e) => setTag(e.target.value)}
              id="tag"
              placeholder="Tag"
              className={`form-control ${error ? (!tag ? "invalid" : "") : ""}`}
              value={tag}
            />
          </FormGroup>
        </div>
        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
