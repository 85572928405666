import { apiSlice } from "../api/apiSlice";
import deleteCollection from "../firebase/deleteCollection";
import getCollection from "../firebase/getCollection";
import getSingleCollection from "../firebase/getSingleCollection";
import setCollection from "../firebase/setCollection";
import updateCollection from "../firebase/updateCollection";

export const servicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("services");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["Services"],
    }),
    getServicesSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`/services/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    addServices: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("services", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Services"],
    }),
    updateServices: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`services/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Services"],
    }),
    deleteServices: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`services`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Services"],
    }),
    addServicesIcon: builder.mutation({
      async queryFn(data) {
        try {
          let store = await setCollection("servicesIcon", data);
          return store;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["ServicesIcon"],
    }),
    updateServicesIcon: builder.mutation({
      async queryFn({ id, data }) {
        try {
          data.id = id;
          let update = await updateCollection(`servicesIcon/${id}`, data);
          return update;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["ServicesIcon"],
    }),
    deleteServicesIcon: builder.mutation({
      async queryFn(id) {
        try {
          let deleteId = await deleteCollection(`servicesIcon`, id);
          return deleteId;
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["ServicesIcon"],
    }),
    getServicesIconSingle: builder.mutation({
      async queryFn(id) {
        try {
          const data = await getSingleCollection(`/servicesIcon/${id}`);
          return data;
        } catch (error) {
          return { error };
        }
      },
    }),
    getServicesIcon: builder.query({
      async queryFn() {
        try {
          const data = await getCollection("servicesIcon");
          return data;
        } catch (error) {
          return { error };
        }
      },
      providesTags: ["ServicesIcon"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServicesSingleMutation,
  useAddServicesMutation,
  useUpdateServicesMutation,
  useDeleteServicesMutation,
  useAddServicesIconMutation,
  useDeleteServicesIconMutation,
  useGetServicesIconSingleMutation,
  useUpdateServicesIconMutation,
  useGetServicesIconQuery,
} = servicesApi;
