import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormContainer from "../../components/FormContainer";
import FormGroup from "../../components/FromGroup";
import imageUpload from "../../features/firebase/imageUpload";
import makeImageUrl from "../../features/firebase/makeImageUrl";

import { toast } from "react-toastify";
import {
  useAddTestimonialMutation,
  useGetTestimonialSingleMutation,
  useUpdateTestimonialMutation,
} from "../../features/testimonials/testimonialsApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function TestimonialAdd() {
  const [name, setName] = useState("");
  const [institute, setInstitute] = useState("");
  const [position, setPosition] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState(null);
  const { height } = useWindowDimensions();
  const [prevImage, setPrevImage] = useState("");
  const navigate = useNavigate();

  const [
    addTestimonial,
    { isError: addIsError, isLoading: addIsLoading, isSuccess: addIsSuccess },
  ] = useAddTestimonialMutation();
  const [
    getTestimonialSingle,
    { data: testimonialData, isSuccess: isSuccessSingleData },
  ] = useGetTestimonialSingleMutation();
  const [
    updateTestimonial,
    {
      isError: updatedIsError,
      isLoading: updatedIsLoading,
      isSuccess: updatedIsSuccess,
    },
  ] = useUpdateTestimonialMutation();
  const { id } = useParams();

  useEffect(() => {
    if (!addIsError && addIsSuccess) {
      toast.info("Testimonial Insert Successfully");
      navigate("/user/testimonial/list");
    } else if (addIsError) {
      toast.error("Testimonial Insert Error!");
    }
  }, [addIsError, addIsSuccess, navigate]);

  useEffect(() => {
    if (!updatedIsError && updatedIsSuccess) {
      toast.info("Testimonial Update Successfully");
      navigate("/user/testimonial/list");
    } else if (updatedIsError) {
      toast.error("Testimonial Update Error!");
    }
  }, [updatedIsError, updatedIsSuccess, navigate]);

  useEffect(() => {
    if (isSuccessSingleData && testimonialData?.id) {
      setName(testimonialData?.name);
      setInstitute(testimonialData?.institute);
      setPosition(testimonialData?.position);
      setDescription(testimonialData?.description);
      setPrevImage(testimonialData?.image);
    }
  }, [isSuccessSingleData, testimonialData]);

  useEffect(() => {
    if (id) {
      getTestimonialSingle(id);
    }
  }, [id, getTestimonialSingle]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && institute) {
      let image_url = prevImage;
      if (image) {
        const image_upload = await imageUpload(image, "Testimonial");
        if (image_upload.state === "success")
          image_url = await makeImageUrl(image, "Testimonial");
      }
      if (id) {
        updateTestimonial({
          id,
          data: {
            name,
            institute,
            position,
            description,
            image: image_url,
          },
        });
      } else {
        addTestimonial({
          name,
          institute,
          position,
          description,
          image: image_url,
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <section
      id="testimonial"
      data-nav-tooltip="Testimonial Entry"
      className="pp-section pp-scrollable section dark-bg"
      style={{ height: `${height}px` }}
    >
      <FormContainer id="testimonial" handleSubmit={handleSubmit}>
        <div className="col-md-10">
          <FormGroup title="Name">
            <input
              name="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              id="name"
              placeholder="Name*"
              className={`form-control ${
                error ? (!name ? "invalid" : "") : ""
              }`}
              type="text"
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Institute">
            <input
              type="text"
              name="Institute"
              onChange={(e) => setInstitute(e.target.value)}
              id="institute"
              placeholder="Institute"
              className={`form-control ${
                error ? (!institute ? "invalid" : "") : ""
              }`}
              value={institute}
            />
          </FormGroup>
        </div>

        <div className="col-md-10">
          <FormGroup title="Position">
            <input
              type="text"
              name="Position"
              onChange={(e) => setPosition(e.target.value)}
              id="position"
              placeholder="Position"
              className={`form-control ${
                error ? (!position ? "invalid" : "") : ""
              }`}
              value={position}
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Description">
            <input
              type="text"
              name="Description"
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              placeholder="Description"
              className={`form-control ${
                error ? (!description ? "invalid" : "") : ""
              }`}
              value={description}
            />
          </FormGroup>
        </div>
        <div className="col-md-10">
          <FormGroup title="Image">
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              id="image"
              placeholder="Image"
              className={`form-control ${
                error ? (!image ? "invalid" : "") : ""
              }`}
            />
          </FormGroup>
        </div>

        <div className="text-center col-md-12">
          <div className="send">
            <input
              className="px-btn px-btn-theme"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </FormContainer>
    </section>
  );
}
