import PortfolioTableRow from "../../components/Portfolio/PortfolioTableRow";
import Table from "../../components/Table";
import Error from "../../components/ui/Error";
import { useGetPortfolioQuery } from "../../features/portfolio/portfolioApi";
import useWindowDimensions from "../../hooks/useWindowDimensions";
export default function PortfolioList() {
  const { height } = useWindowDimensions();
  const {
    data: portfolioData,
    isLoading,
    isError,
    isSuccess,
  } = useGetPortfolioQuery();
  console.log(portfolioData);
  let content = "";
  if (isLoading) {
    content = <p>Loading..</p>;
  } else if (!isLoading && isError) {
    content = <Error message="There is some problem" />;
  } else if (isSuccess && portfolioData.length === 0) {
    content = <Error message="There is no data" />;
  } else if (isSuccess && portfolioData.length > 0) {
    content = (
      <Table>
        <thead>
          <tr className="text-center">
            <th>Sl</th>
            <th>Title</th>
            <th>Description</th>
            <th>Link</th>
            <th>Tag</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {portfolioData.map((portfolio) => (
            <PortfolioTableRow key={portfolio?.id} portfolio={portfolio} />
          ))}
        </tbody>
      </Table>
    );
  }
  return (
      <section
        id="portfolio"
        data-nav-tooltip="Portfolios"
        className="pp-section pp-scrollable section dark-bg"
        style={{ height: `${height}px` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">{content}</div>
          </div>
        </div>
      </section>
  );
}
